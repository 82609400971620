<template>
  <div class="idxgame hotpro-row idxGame05">
    <h2 class="sectionTitle">
      <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/idx-title-popular.webp`)" fluid alt="眾多熱門遊戲"></b-img>
    </h2>
      <b-tabs class="mb-3">

        <b-tab>
          <template v-slot:title>
            <span>{{ $t('store.s_hot') }}</span>
          </template>
          <div class="itemBox item_0">
            <div class="leftBox">
              <div class="idg_title">
                <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/idx-game-hot.webp`)" fluid alt="眾多熱門遊戲"></b-img>
              </div>
              <div class="idg_relate">
                <p v-for="(item, index) in idxContact" :key="index">{{ item.hot }}</p>
              </div>
              <ul class="idg_btn">
                <template v-for="Item in HotGameList">
                  <li>
                    <a @click="OpenGame(Item.CategoryId, Item.Id, false)" :class="`idg_list product_${Item.Id} ${Item.Maintain}`">
                      <span class="itemBg">
                        <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-bg.webp`)" fluid :alt="Item.Name"></b-img>
                      </span>
                      <span class="itemContent">
                        <i :class="`idg_logo product_${Item.Id}`"></i>
                        <span class="itemName">{{ Item.Name }}</span>
                      </span>
                      <span class="upholdBox">
                        <span class="upholdImg">
                          <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-uphold.webp`)" fluid :alt="Item.Name"></b-img>
                        </span>
                        <span class="upholdText">{{ $t('store.uphold_ing') }}</span>
                      </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <div class="rightBox">
              <div class="idg_img">
                <img :src="require(`@/website/${DEF_webStyle}/images/pc/hotpro_hot.webp`)" class="img-fluid">
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab v-for="(item, index) in Seo" :key="index">
          <template v-slot:title>
            <span>{{ item.title }}</span>
          </template>
          <div :class="`itemBox item_${item.id}`">
            <div class="leftBox">
              <div class="idg_title">
                <img :src="require(`@/website/${DEF_webStyle}/images/pc/idx-game-${item.id}.webp`)" class="img-fluid">
              </div>
              <div class="idg_relate" v-html="item.description"></div>
              <ul class="idg_btn">
                <template v-for="link in Product(item.id)" v-if="item.id < 6">
                  <li>
                    <a @click="OpenGame(item.id, link.Id, false)" :class="`idg_list product_${link.Id} ${link.Maintain}`" :data-text="$t('store.uphold_ing')">
                      <span class="itemBg">
                        <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-bg.webp`)" fluid :alt="link.GameName"></b-img>
                      </span>
                      <span class="itemContent">
                        <i :class="`idg_logo product_${link.Id}`"></i>
                        <span class="itemName">{{ link.GameName }}</span>
                      </span>
                      <span class="upholdBox">
                        <span class="upholdImg">
                          <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-uphold.webp`)" fluid :alt="link.GameName"></b-img>
                        </span>
                        <span class="upholdText">{{ $t('store.uphold_ing') }}</span>
                      </span>
                    </a>
                  </li>
                </template>
                <template v-if="item.id === 6" >
                  <li>
                    <a @click="OpenGame(item.id, 0, false)" class="idg_list product_10">
                      <span class="itemBg">
                        <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-bg.webp`)" fluid :alt="$t('store.fish_GR')"></b-img>
                      </span>
                      <span class="itemContent">
                        <i class="idg_logo product_10"></i>
                        <span class="itemName">{{ $t("store.fish_GR") }}</span>
                      </span>
                      <span class="upholdBox">
                        <span class="upholdImg">
                          <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-uphold.webp`)" fluid :alt="$t('store.fish_GR')"></b-img>
                        </span>
                        <span class="upholdText">{{ $t('store.uphold_ing') }}</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a @click="OpenGame(item.id, 0, false)" class="idg_list product_26">
                      <span class="itemBg">
                        <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-bg.webp`)" fluid :alt="$t('store.fish_OB')"></b-img>
                      </span>
                      <span class="itemContent">
                        <i class="idg_logo product_26"></i>
                        <span class="itemName">{{ $t("store.fish_OB") }}</span>
                      </span>
                      <span class="upholdBox">
                        <span class="upholdImg">
                          <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-uphold.webp`)" fluid :alt="$t('store.fish_OB')"></b-img>
                        </span>
                        <span class="upholdText">{{ $t('store.uphold_ing') }}</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a @click="OpenGame(item.id, 0, false)" class="idg_list product_45">
                      <span class="itemBg">
                        <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-bg.webp`)" fluid :alt="$t('store.fish_RSG')"></b-img>
                      </span>
                      <span class="itemContent">
                        <i class="idg_logo product_45"></i>
                        <span class="itemName">{{ $t("store.fish_RSG") }}</span>
                      </span>
                      <span class="upholdBox">
                        <span class="upholdImg">
                          <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-uphold.webp`)" fluid :alt="$t('store.fish_RSG')"></b-img>
                        </span>
                        <span class="upholdText">{{ $t('store.uphold_ing') }}</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a @click="OpenGame(item.id, 0, false)" class="idg_list product_46">
                      <span class="itemBg">
                        <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-bg.webp`)" fluid :alt="$t('store.fish_ZG')"></b-img>
                      </span>
                      <span class="itemContent">
                        <i class="idg_logo product_46"></i>
                        <span class="itemName">{{ $t("store.fish_ZG") }}</span>
                      </span>
                      <span class="upholdBox">
                        <span class="upholdImg">
                          <b-img :src="require(`@/website/${DEF_webStyle}/images/game/desktop/idx-game-uphold.webp`)" fluid :alt="$t('store.fish_ZG')"></b-img>
                        </span>
                        <span class="upholdText">{{ $t('store.uphold_ing') }}</span>
                      </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <div class="rightBox">
              <div class="idg_img">
                <img :src="item.image" :alt="item.title" class="img-fluid">
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  export default {
    data() {
      return {
        idxContact: null,
        hotGame: null,
        list: [],
      };
    },
    created: function () {
      if (this.idxContact == null)
        this.idxContact = getJsonFile(process.env.DEF_webStyle, 'idxContact');
      if (this.hotGame == null)
        this.hotGame = getJsonFile(process.env.DEF_webStyle, 'hotGame');
    },
    methods: {
      OpenGame (CategoryId, ProductId, IsTry) {
        if ([3, 7, 8].indexOf(CategoryId) >= 0) {
          this.$router.replace({ path: "/game/egame", query: { ProductId }});
          return;
        }

        if ([6].indexOf(CategoryId) >= 0) {
          this.$router.replace({ path: "/game/fish"});
          return;
        }

        let GameData = this.Product(CategoryId).find(element => element.Id === ProductId);
        if (!GameData)
          return;

        let Maintain = GameData.Maintain;
        if (Maintain != "")
          return;

        if (this.$auth.$state.loggedIn === false) {
          this.$router.replace({ path: "/account/login"});
          return;
        }

        if (IsTry) {
          window.open(GameData.UrlTry);
          return;
        }

        if (!this.ShowTransferModal) {
          window.open(GameData.Url);
          return;
        }

        this.setPlayGameData({ WalletCode: GameData.WalletCode, Url: GameData.Url });
        this.$nextTick(() => this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle"));
        return;
      },
      ...mapMutations("game", ["setPlayGameData"]),
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      HotGameList() {
        if (!this.hotGame.length || this.hotGame.length == 0)
          return [];

        let Data = [];
        this.hotGame.forEach(Item => {
          let CategoryData = this.MobileMeun.find(Element => Element.id === Item.category_id);
          if (!CategoryData)
            return;

          let ProductData = CategoryData.tabs.find(Element => Element.Id === Item.product_id);
          if (!ProductData)
            return;

          Data.push({
            Url: CategoryData.url,
            Id: ProductData.Id,
            CategoryId: CategoryData.id,
            Maintain: ProductData.Maintain,
            Name: ProductData.GameName
          });
        });

        return Data;
      },
      ...mapState(['WebSetting']),
      ...mapGetters(["GameMeun", "Product", "Seo", "MobileMeun"]),
      ...mapGetters('webSetting', ['ShowTransferModal'])
    },
  };
</script>
